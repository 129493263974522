<template>
  <v-app style="background: white">
    <v-container>
      <v-row>
        <v-col cols="12" class="topMenu">
          <v-toolbar flat>
            <v-toolbar-title class="title">
              <img alt="Callisto Network" src="./assets/logo.png" height="50" />
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn text href="https://callisto.network/">Home</v-btn>
            <v-btn
              text
              href="https://docs.callisto.network/callisto-network/callisto-network-masternodes"
              >About</v-btn
            >
          </v-toolbar>
        </v-col>
      </v-row>

      <h1 class="pagetitle" @click="admin()">MasterNodes</h1>
      <div v-if="isAdmin && chain == 820">
        <h2>Admin</h2>

        <v-text-field
          v-model="node"
          placeholder="Add new node"
          append-icon="mdi-plus"
          @click:append="addNode()"
        ></v-text-field>
      </div>
      <v-divider class="ma-4"></v-divider>
      <v-data-table
        class="activeNodes"
        :headers="headers"
        :items="activeNodes"
        :items-per-page="-1"
      >
      </v-data-table>
      <v-divider class="ma-4"></v-divider>
      <h1 class="pagetitle">Waiting Queue</h1>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
      <v-data-table
        :headers="headers"
        :items="nodes"
        :items-per-page="20"
        :search="search"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
      >
      </v-data-table>

      <v-alert
        border="right"
        colored-border
        type="success"
        elevation="2"
        icon="mdi-information"
      >
        Wallets are listed 24 hours after filling out the application form
        available <a href="https://clo.click/JoinTheQueue">here</a>.
      </v-alert>
    </v-container>

    <v-footer padless class="footer">
      <v-col class="text-center" cols="12">
        <span
          >All rights reserved by
          <a href="https://callisto.network/">Callisto Network</a> &copy; 2020
        </span>
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
const Web3 = require("web3");
const clo = new Web3("https://rpc.callisto.network/");
const web3 = new Web3(window.ethereum || Web3.givenProvider);
//const clo = new Web3("https://testnet-rpc.callisto.network/");
const contract = new clo.eth.Contract(
  [
    {
      inputs: [
        {
          internalType: "address",
          name: "_firstAdmin",
          type: "address",
        },
      ],
      stateMutability: "nonpayable",
      type: "constructor",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "_admin",
          type: "address",
        },
      ],
      name: "AdminAdded",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "_admin",
          type: "address",
        },
      ],
      name: "AdminBlocked",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "_node",
          type: "address",
        },
      ],
      name: "NodeAdded",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "_node",
          type: "address",
        },
      ],
      name: "NodeLocked",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "_node",
          type: "address",
        },
      ],
      name: "NodeRemoved",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "_node",
          type: "address",
        },
      ],
      name: "NodeUnlocked",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "_node",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_points",
          type: "uint256",
        },
      ],
      name: "PointsGranted",
      type: "event",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "_admin",
          type: "address",
        },
      ],
      name: "addAdmin",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "_node",
          type: "address",
        },
      ],
      name: "addNode",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "",
          type: "address",
        },
      ],
      name: "admins",
      outputs: [
        {
          internalType: "bool",
          name: "",
          type: "bool",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "_admin",
          type: "address",
        },
      ],
      name: "blockAdmin",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "_node",
          type: "address",
        },
        {
          internalType: "uint256",
          name: "_points",
          type: "uint256",
        },
      ],
      name: "grantPoints",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "_node",
          type: "address",
        },
      ],
      name: "listNodeByOwner",
      outputs: [
        {
          components: [
            {
              internalType: "address",
              name: "owner",
              type: "address",
            },
            {
              internalType: "uint256",
              name: "points",
              type: "uint256",
            },
            {
              internalType: "bool",
              name: "state",
              type: "bool",
            },
            {
              internalType: "uint256",
              name: "lastAwardTime",
              type: "uint256",
            },
          ],
          internalType: "struct MasterNodesTest.Node",
          name: "",
          type: "tuple",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "_node",
          type: "address",
        },
      ],
      name: "lockNode",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [],
      name: "nodeCounter",
      outputs: [
        {
          internalType: "uint256",
          name: "",
          type: "uint256",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "uint256",
          name: "",
          type: "uint256",
        },
      ],
      name: "nodesListByIndex",
      outputs: [
        {
          internalType: "address",
          name: "owner",
          type: "address",
        },
        {
          internalType: "uint256",
          name: "points",
          type: "uint256",
        },
        {
          internalType: "bool",
          name: "state",
          type: "bool",
        },
        {
          internalType: "uint256",
          name: "lastAwardTime",
          type: "uint256",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "_node",
          type: "address",
        },
      ],
      name: "removeNode",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "_node",
          type: "address",
        },
      ],
      name: "unlockNode",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "_node",
          type: "address",
        },
        {
          internalType: "uint256",
          name: "_index",
          type: "uint256",
        },
      ],
      name: "useFreeNode",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
  ],
  "0x123b2a6837cB691D93Db30A1fD9f973Ca761a348"
);

const isActive = new clo.eth.Contract(
  [
    {
      type: "function",
      stateMutability: "view",
      outputs: [
        {
          type: "tuple",
          name: "node",
          internalType: "struct MasterNodes.Node",
          components: [
            { type: "address", name: "owner", internalType: "address" },
            { type: "bool", name: "isActive", internalType: "bool" },
            { type: "uint8", name: "isUser", internalType: "uint8" },
            {
              type: "uint256[3]",
              name: "balances",
              internalType: "uint256[3]",
            },
            {
              type: "uint256[3]",
              name: "rewardPerShares",
              internalType: "uint256[3]",
            },
            {
              type: "uint256",
              name: "unlockTime",
              internalType: "uint256",
            },
            { type: "string", name: "url", internalType: "string" },
          ],
        },
        { type: "address", name: "authority", internalType: "address" },
      ],
      name: "getUsersNodeByOwner",
      inputs: [{ type: "address", name: "owner", internalType: "address" }],
    },
  ],
  "0x2521254020fA0dffca72D2F8C93e0a06e03c7DC2"
);

const write = new web3.eth.Contract(
  [
    {
      inputs: [
        {
          internalType: "address",
          name: "_node",
          type: "address",
        },
      ],
      name: "addNode",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "_node",
          type: "address",
        },
        {
          internalType: "uint256",
          name: "_points",
          type: "uint256",
        },
      ],
      name: "grantPoints",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
  ],
  "0x123b2a6837cB691D93Db30A1fD9f973Ca761a348"
);

export default {
  name: "App",
  data: () => ({
    wallet: "",
    isAdmin: false,
    chain: "",
    node: "",
    search: "",
    nodes: [],
    activeNodes: [],
    sortBy: "points",
    sortDesc: true,
    headers: [
      {
        text: "Address",
        align: "start",
        sortable: true,
        value: "short",
      },
      { text: "Balance", value: "balance" },
      { text: "Points", value: "points" },
      { text: "Last Award", value: "lastAwardTime" },
      { text: "Status", value: "status.emoji" },
    ],
  }),
  async beforeMount() {
    this.getNodes();
  },
  methods: {
    admin: async function () {
      web3.eth.requestAccounts().then(async (addresses) => {
        this.wallet = addresses[0];
        this.isAdmin = await contract.methods.admins(this.wallet).call();
      });
      this.chain = await web3.eth.getChainId();
    },
    addNode: async function () {
      await write.methods
        .addNode(this.node)
        .send({ from: this.wallet })
        .then(() => {
          alert("Node added");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getNodes: async function () {
      try {
        const nodesAmount = await contract.methods.nodeCounter().call();
        const nodePromises = [];

        for (let i = 0; i < nodesAmount; i++) {
          nodePromises.push(contract.methods.nodesListByIndex(i).call());
        }

        const nodes = await Promise.all(nodePromises);

        const filteredNodesPromises = nodes.map(async (node) => {
          if (node.owner !== "0x0000000000000000000000000000000000000000") {
            const balance = await clo.eth.getBalance(node.owner);
            node.balance = parseFloat(clo.utils.fromWei(balance)).toFixed(0);
            node.lastAwardTime = this.timeConverter(node.lastAwardTime);
            node.short =
              node.owner.substring(0, 10) +
              "***" +
              node.owner.substring(node.owner.length - 5, node.owner.length);
            node.status = await this.getNodeStatus(node.owner);

            return node;
          }
          return null;
        });

        const filteredNodes = await Promise.all(filteredNodesPromises);

        this.activeNodes = filteredNodes.filter(
          (node) => node !== null && node.status.detail === "Active"
        );

        this.nodes = filteredNodes.filter(
          (node) => node !== null && node.status.detail !== "Active"
        );
      } catch (err) {
        console.error("Error in getNodes:", err);
      }
    },
    getNodeStatus: async function (owner) {
      const status = (
        await isActive.methods.getUsersNodeByOwner(owner).call()
      )[0];
      if (status.isActive === true) {
        return { emoji: "🟢", detail: "Active" };
      }
      if (
        status.isActive === false &&
        status.owner != "0x0000000000000000000000000000000000000000"
      ) {
        return { emoji: "🟡", detail: "Not active" };
      }
      return { emoji: "🔴", detail: "Not added" };
    },

    timeConverter: function (UNIX_timestamp) {
      const date = new Date(UNIX_timestamp * 1000);
      const dateFormatter = new Intl.DateTimeFormat("en-GB", {
        year: "numeric",
        month: "short",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
      });

      const formattedDate = dateFormatter.format(date);
      return formattedDate;
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap");

.main {
  padding: 20px;
}

h1 {
  text-align: center;
}

h3 {
  margin-bottom: 20px;
}

p {
  margin-left: 20px;
}

.v-btn {
  margin: 20px;
}

.topMenu {
  margin-top: 20px;
}

.pagetitle {
  margin-top: 45px;
}

.footer {
  margin-top: 140px;
}

.activeNodes .v-data-footer {
  display: none;
}
</style>
